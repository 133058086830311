/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Wobbling dots loader */
.wobbling-dots {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .dot {
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background-color: white;
    border-radius: 50%;
    animation: wobble 0.6s infinite ease-in-out both;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes wobble {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  